.nav-buttons-container {
    display: flex;
    height: 40px;
    padding: 24px;
    width: 100%;
}

.scrollbar-container {
    position: relative;
    height: 100%;
    overflow: hidden !important;
}