.scrolling-image-container {
    width: 100%;
    overflow: hidden;
}

.scrolling-image-wrapper {
    display: flex;
    animation: scroll 20s linear infinite;
    width: fit-content;
}

.scrolling-image {
    width: 1665px;
    object-fit: cover;
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    /* Vendor prefixes are necessary */
    100% {
        -webkit-transform: translateX(calc(-25% + 1665px));
        -moz-transform: translateX(calc(-25% + 1665px));
        -ms-transform: translateX(calc(-25% + 1665px));
        -o-transform: translateX(calc(-25% + 1665px));
        transform: translateX(calc(-25% + 1665px));
    }
}
